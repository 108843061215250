import React, { useEffect, useState } from "react";
import Header from "./Header";
import Loader from "./Loader";
import Footer from "./Footer";

const Actualites = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        <section
          className="page-title bg-overlay-black-60 parallax"
          style={{
            backgroundImage:
              "url(/assets/images/actualité/slider-actualite.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>ACTUALITÉS</h1>
                  <p>Quoi De Neuf Chez SACEM ?</p>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home" /> Accueil
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>Actualités</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
 Grid-1 */}
        <div className="page-section-ptb">
          <section className="page-section-pb white-bg">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="section-title text-center">
                    <h6>ACTUALITÉS</h6>
                    <h2 className="title-effect"> SACEM GROUPE</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-sm-6 sm-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/home/ACT1.jpg"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        Le nouveau site de production SACEM POWER SYSTEMS.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 sm-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/home/ACT2.jpg"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        La renovation de la bibliotheque nationale Menzel
                        bourguiba.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 xs-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/home/ACT3.jpg"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        Export Transformateur de puissance 20 MVA.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/home/ACT4.jpg"
                      alt
                    />
                    <div className="content">
                      <h6> SACEM </h6>
                      <p className="mb-0">
                        Convention avec la municipalité de TINJAH à l'occasion
                        de la journée Education environementale pour une
                        mediterranée durable
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/**</div> */}

              {/** */}
            </div>
          </section>
          {/*=================================
 Grid-2 */}
          <section className="page-section-pb white-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-sm-6 sm-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act5.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 sm-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act6.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 xs-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act7.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act8.png"
                      alt
                    />
                    <div className="content">
                      <h6> SACEM </h6>
                      <p className="mb-0">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Illo, dolorem officia, vitae officiis, accusantium iusto
                        fugit est unde eum deleniti quae perferendis laborum
                        esse excepturi neque eaque delectus ab quaerat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*=================================
 Grid-3 */}
          <section className="page-section-pb white-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-sm-6 sm-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act9.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 sm-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act10.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 xs-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act11.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act12.png"
                      alt
                    />
                    <div className="content">
                      <h6> SACEM </h6>
                      <p className="mb-0">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Illo, dolorem officia, vitae officiis, accusantium iusto
                        fugit est unde eum deleniti quae perferendis laborum
                        esse excepturi neque eaque delectus ab quaerat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*=================================
 Grid-4 */}
          <section className="page-section-pb white-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-sm-6 sm-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act13.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 sm-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act14.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 xs-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act15.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act16.png"
                      alt
                    />
                    <div className="content">
                      <h6> SACEM </h6>
                      <p className="mb-0">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Illo, dolorem officia, vitae officiis, accusantium iusto
                        fugit est unde eum deleniti quae perferendis laborum
                        esse excepturi neque eaque delectus ab quaerat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*=================================
 Grid-5 */}
          <section className="page-section-pb white-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-sm-6 sm-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act17.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 sm-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act18.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 xs-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act19.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act20.png"
                      alt
                    />
                    <div className="content">
                      <h6> SACEM </h6>
                      <p className="mb-0">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Illo, dolorem officia, vitae officiis, accusantium iusto
                        fugit est unde eum deleniti quae perferendis laborum
                        esse excepturi neque eaque delectus ab quaerat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*=================================
 Grid-6 */}
          <section className="page-section-pb white-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-sm-6 sm-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act21.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 sm-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act22.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 xs-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act23.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act24.png"
                      alt
                    />
                    <div className="content">
                      <h6> SACEM </h6>
                      <p className="mb-0">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Illo, dolorem officia, vitae officiis, accusantium iusto
                        fugit est unde eum deleniti quae perferendis laborum
                        esse excepturi neque eaque delectus ab quaerat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*=================================
 Grid-7 */}
          <section className="page-section-pb white-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-sm-6 sm-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act25.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 sm-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act26.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 xs-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act27.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act28.png"
                      alt
                    />
                    <div className="content">
                      <h6> SACEM </h6>
                      <p className="mb-0">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Illo, dolorem officia, vitae officiis, accusantium iusto
                        fugit est unde eum deleniti quae perferendis laborum
                        esse excepturi neque eaque delectus ab quaerat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*=================================
 Grid-8 */}
          <section className="page-section-pb white-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-sm-6 sm-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act29.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 sm-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act30.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 xs-mb-30">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act31.png"
                      alt
                    />
                    <div className="content">
                      <h6>SACEM</h6>
                      <p className="mb-0">
                        "Innovative Technologies to Bring Electricity."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="about-mission-box white-bg h-100">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/actualité/actualité 262X180 px/act32.png"
                      alt
                    />
                    <div className="content">
                      <h6> SACEM </h6>
                      <p className="mb-0">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Illo, dolorem officia, vitae officiis, accusantium iusto
                        fugit est unde eum deleniti quae perferendis laborum
                        esse excepturi neque eaque delectus ab quaerat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*=================================
 Grid-9 */}
          {/**
      <section className="page-section-pb white-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6 sm-mb-30">
              <div className="about-mission-box white-bg h-100">
                <img
                  className="img-fluid mx-auto"
                  src="/assets/images/actualité/actualité 262X180 px/act33.png"
                  alt
                />
                <div className="content">
                  <h6>SACEM</h6>
                  <p className="mb-0">
                    "Innovative Technologies to Bring Electricity."
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 sm-mb-30">
              <div className="about-mission-box white-bg h-100">
                <img
                  className="img-fluid mx-auto"
                  src="/assets/images/actualité/actualité 262X180 px/act34.png"
                  alt
                />
                <div className="content">
                  <h6>SACEM</h6>
                  <p className="mb-0">
                    "Innovative Technologies to Bring Electricity."
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 xs-mb-30">
              <div className="about-mission-box white-bg h-100">
                <img
                  className="img-fluid mx-auto"
                  src="/assets/images/actualité/actualité 262X180 px/act35.png"
                  alt
                />
                <div className="content">
                  <h6>SACEM</h6>
                  <p className="mb-0">
                    "Innovative Technologies to Bring Electricity."
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="about-mission-box white-bg h-100">
                <img
                  className="img-fluid mx-auto"
                  src="/assets/images/actualité/actualité 262X180 px/act36.png"
                  alt
                />
                <div className="content">
                  <h6> SACEM </h6>
                  <p className="mb-0">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Illo, dolorem officia, vitae officiis, accusantium iusto
                    fugit est unde eum deleniti quae perferendis laborum esse
                    excepturi neque eaque delectus ab quaerat.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
       */}
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Actualites;
