import React, { useEffect, useState } from "react";
import Form from "./Form";
import NosAdresses from "./NosAdresses";
import NosCommerciaux from "./NosCommerciaux";
import Header from "./Header";
import Loader from "./Loader";
import Footer from "./Footer";
import Fortest2 from "./Fortest2";

const Nouscontacter = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div style={{ paddingTop: "120px" }}>
        {/*=================================
page-title*/}

        {/*=================================
Teams */}
        <section className="page-section-ptb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect">Commerciaux SACEM</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div style={{ width: "1170px", paddingBottom: "40px" }}>
                <NosCommerciaux />
              </div>

              {/*=================================Form */}
              <div
                style={{
                  width: "1170px",
                  paddingTop: "40px",
                  paddingBottom: "20px",

                  border: "2px solid #eeeeee",
                }}
              >
                <Form />
              </div>

              {/*=================================Maps */}
              <div
                style={{
                  width: "1170px",
                  paddingTop: "40px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <NosAdresses />
              </div>

              {/*=================================Form */}
            </div>
          </div>
        </section>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Nouscontacter;
