import React from "react";
//import ReactDOM from "react-dom/client";
//import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import App from "./App";
//const root = ReactDOM.createRoot(document.getElementById("root"));
const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
