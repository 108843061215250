import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "./Footer";
import Loader from "./Loader";
import Header from "./Header";
const Power = () => {
  const StyledHeading = styled.h6`
    position: relative;
    font-size: 100px;
    color: #008bcd;

    &::before {
      content: "—";
      font-size: inherit;
      color: #008bcd;
      display: block;
    }
  `;
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        <section
          className="page-title bg-overlay-black-60 parallax"
          style={{
            backgroundImage: "url(/assets/images/slidebat.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>SACEM INDUSTRIES</h1>
                  <p>Innovative Technologies to Bring Electricity</p>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home"></i> Accueil
                    </a>{" "}
                    <i className="fa fa-angle-double-right"></i>
                  </li>
                  <li>
                    <span>Transformateur</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/*=================================
page-title */}
        {/*=================================
feature */}
        <section className="service white-bg page-section-ptb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect">
                    Nos Gammes de Produits Power Systems
                  </h2>
                </div>
              </div>
            </div>
            {/* ============================================ */}
            <div className="service-3">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  {/** */}
                  <div
                    className="section-title slideInLeft wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInLeft",
                    }}
                  >
                    <div
                      className="service-blog text-left"
                      style={{ marginBottom: "50px" }}
                    >
                      <h3 className="theme-color">Tableau-AIS</h3>
                      <StyledHeading className="h6-line"></StyledHeading>
                      <p>
                        Nos cellules moyenne tension 36 kV modulaires, équipées
                        d'interrupteurs sous SF6 et de jeux de barres dans
                        l'air, sont fabriquées conformément à la norme
                        internationale IEC-62271-200. Homologuées par la STEG et
                        déjà installées dans le réseau Tunisien.
                      </p>
                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderRight: "0px solid white" }}
                      >
                        <li>SME01 : Cellule réseau (Arrivée/Départ)</li>
                        <li>
                          SME02 : Cellule de protection transformateur par
                          fusible
                        </li>
                        <li>SME03 : Cellule de mesure tension</li>
                        <li>
                          SME05 : Cellule disjoncteur double sectionnement
                          (cellule de couplage)
                        </li>
                      </ul>
                    </div>

                    <div className="entry-button">
                      <a className="button arrow" href="/tableauais">
                        Lire plus
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </a>
                      <a
                        className="button button-border"
                        style={{ marginLeft: "250px" }}
                        href="/contact"
                      >
                        Nous Contacter
                      </a>
                    </div>
                  </div>

                  {/** */}
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div
                      className="col-lg-12 col-md-12"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/images/cellule trio.png"
                          alt=""
                          style={{ maxWidth: "80%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ============================================ */}
              <div className="row">
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        className="img-fluid"
                        src="/assets/images/CP1T.png"
                        alt=""
                        style={{ maxWidth: "80%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  {/** */}
                  <div
                    className="slideInRight wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInRight",
                    }}
                  >
                    <div
                      className="service-blog left text-left"
                      style={{ marginBottom: "50px" }}
                    >
                      <h3 className="theme-color">
                        Coffret Protection Transformateur
                      </h3>
                      <StyledHeading className="h6-line"></StyledHeading>
                      <p>
                        Le coffret de protection de transformateur est destiné à
                        assurer la surveillance et la protection des
                        transformateurs immergés par un déclenchement amont et
                        aval des appareils de protection qui y sont associés.
                      </p>
                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderLeft: "0px solid white" }}
                      >
                        <li>CP1T : Coffret de protection un transformateurs</li>
                        <li>CP2T : Coffret de protection un transformateurs</li>
                        <li>Avec relais DMCR et DGPT2</li>
                        <li>Avec relais BUCCHOLZ</li>
                      </ul>
                    </div>
                    <div className="entry-button">
                      <a
                        className="button arrow"
                        href="/Coffret_Protection_Transformateur"
                      >
                        Lire plus
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </a>
                      <a
                        className="button button-border"
                        style={{ marginLeft: "250px" }}
                        href="/contact"
                      >
                        Nous Contacter
                      </a>
                    </div>
                  </div>
                  {/** */}
                </div>
              </div>
              {/* ============================================ */}
              <div className="row">
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  {/** */}
                  <div
                    className="section-title slideInLeft wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInLeft",
                    }}
                  >
                    <div
                      className="service-blog text-left"
                      style={{ marginBottom: "50px" }}
                    >
                      <h3 className="theme-color">
                        Système de Gestion de l'Energie
                      </h3>
                      <StyledHeading className="h6-line"></StyledHeading>
                      <p>
                        SEMS est un système de gestion de l'énergie permettant
                        la supervision en temps réel et à distance des postes de
                        transformation HTA/BT, SEMS permet de mesurer,
                        centraliser les données et les événements collectés dans
                        le poste, et d'assurer l'envoi de ces informations à
                        distance vers une plateforme de monitoring.
                      </p>
                    </div>
                    <div className="entry-button">
                      <a className="button arrow" href="/eems">
                        Lire plus
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </a>
                      <a
                        className="button button-border"
                        style={{ marginLeft: "250px" }}
                        href="/contact"
                      >
                        Nous Contacter
                      </a>
                    </div>
                  </div>
                  {/** */}
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div className="col-lg-12 col-md-12">
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/images/Power/cofr4.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ============================================ */}
              <div className="row">
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        className="img-fluid"
                        src="/assets/images/filtre-active-pqsine.jpg"
                        alt=""
                        style={{ maxWidth: "80%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  {/** */}
                  <div
                    className="slideInRight wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInRight",
                    }}
                  >
                    <div
                      className="service-blog left text-left"
                      style={{ marginBottom: "50px" }}
                    >
                      <h3 className="theme-color">
                        Filtre actif anti-harmoniques
                      </h3>
                      <StyledHeading className="h6-line"></StyledHeading>

                      <p>
                        Notre filtre actif anti-harmonique est conçu pour
                        éliminer les harmoniques présentes dans le réseau
                        électrique, garantissant une alimentation stable et
                        fiable. En réduisant les distorsions et en stabilisant
                        la qualité de l’énergie, il assure la protection de
                        l’ensemble de l’installation et de tous les équipements
                        connectés limitant ainsi les risques de défaillances.{" "}
                      </p>
                    </div>
                    <div className="entry-button">
                      <a className="button arrow" href="/filtreactive">
                        Lire plus
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </a>
                      <a
                        className="button button-border"
                        style={{ marginLeft: "250px" }}
                        href="/contact"
                      >
                        Nous Contacter
                      </a>
                    </div>
                  </div>
                  {/** */}
                </div>
              </div>
              {/* ============================================ */}
              <div className="row">
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  {/** */}
                  <div
                    className="section-title slideInLeft wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInLeft",
                    }}
                  >
                    <div
                      className="service-blog text-left"
                      style={{ marginBottom: "50px" }}
                    >
                      <h3 className="theme-color">Batterie de condensateurs</h3>
                      <StyledHeading className="h6-line"></StyledHeading>
                      <p>
                        Nous proposons une gamme variée de batteries de
                        condensateurs, adaptées aux besoins spécifiques
                        d'optimisation énergétique et de protection du réseau
                      </p>
                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderRight: "0px solid white" }}
                      >
                        <li>Compensation fixe </li>
                        <li>Compensation automatique</li>
                        <li>Compensation pour réseau non pollué </li>
                        <li>Compensation pour réseau peu pollué </li>
                        <li>Compensation pour réseau pollué </li>
                      </ul>
                    </div>
                    <div className="entry-button">
                      <a className="button arrow" href="/batterie_condencateur">
                        Lire plus
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </a>
                      <a
                        className="button button-border"
                        style={{ marginLeft: "250px" }}
                        href="/contact"
                      >
                        Nous Contacter
                      </a>
                    </div>
                  </div>
                  {/** */}
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div
                      className="col-lg-12 col-md-12"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/images/Power/bat-01.png"
                          alt=""
                          style={{ maxWidth: "80%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Power;
