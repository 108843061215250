import React from "react";

const HeaderHome = ({ isVisible }) => {
  return (
    <div style={{ display: isVisible ? "block" : "none" }}>
      {/*=================================
   header */}

      <header id="header" className="header default">
        <div className="topbar">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 xs-mb-10">
                <div className="topbar-call text-center text-md-left">
                  <ul>
                    <li>
                      <i className="fa fa-envelope-o theme-color" />{" "}
                      <a href="mailto:contact@sacemgroup.com">
                        contact@sacemgroup.com
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-phone" />{" "}
                      <a href="tel:0021671966986">
                        {" "}
                        <span>+(216) 71 966 986 </span>{" "}
                      </a>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="topbar-social text-center text-md-right">
                  <ul>
                    <li>
                      <a href="/carriers" style={{ color: "white" }}>
                        Carrière
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/sacemindustries/?locale=fr_FR"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="ti-facebook" />
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://fr.linkedin.com/company/sacem-group"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="ti-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*=================================
   mega menu */}
        <div className="menu">
          <centre>
            {/* menu start */}
            <nav id="menu" className="mega-menu">
              {/* menu list items container */}
              <section className="menu-list-items" style={{ height: "90px" }}>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <ul className="menu-logo">
                        <li>
                          <a href="/">
                            <img
                              id="logo_img"
                              src="/assets/images/logos/logo1.png"
                              alt="logo"
                              style={{ width: "122px", height: "90px" }}
                            />{" "}
                          </a>
                        </li>
                      </ul>
                      {/* menu logo */}

                      {/* menu links */}
                      <div className="menu-bar">
                        <ul className="menu-links">
                          {/* HOME */}
                          <li>
                            <a href="/">Accueil </a>
                          </li>
                          <li>
                            <a>
                              {" "}
                              Entreprise{" "}
                              <i className="fa fa-angle-down fa-indicator" />
                            </a>
                            {/* drop down multilevel  */}
                            <div className="drop-down-multilevel">
                              <ul>
                                <li>
                                  <a href="/presentation">
                                    <i className="fa fa-television" />
                                    Présentation{" "}
                                  </a>
                                </li>
                                <li>
                                  <a href="/histoire">
                                    <i className="fa fa-history" /> Histoire{" "}
                                  </a>
                                </li>
                                <li>
                                  <a href="/actualites">
                                    <i className="fa fa-newspaper-o" />{" "}
                                    Actualités{" "}
                                  </a>
                                </li>
                                <li>
                                  <a href="/certificats">
                                    <i className="fa fa-certificate" />{" "}
                                    Certification{" "}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li>
                            <a href="/produit">
                              Produits{" "}
                              <i className="fa fa-angle-down fa-indicator" />
                            </a>
                            <ul className="drop-down-multilevel left-side">
                              <li>
                                <a href="/transformateur">
                                  Transformateur
                                  <i className="ti-plus fa-indicator" />
                                </a>
                                {/* drop down second level */}
                                <ul className="drop-down-multilevel left-side">
                                  <li>
                                    <a href="#">
                                      <i className="ti-plus fa-indicator" />
                                      Transformateur de distribution Triphasé
                                    </a>
                                    {/* drop down third level */}
                                    <ul className="drop-down-multilevel left-side">
                                      <li>
                                        <a href="/transformateur_dist_24">
                                          Transformateur de distribution
                                          Triphasé classe 24KV
                                        </a>
                                      </li>
                                      <li>
                                        <a href="/transformateur_dist_36">
                                          Transformateur de distribution
                                          Triphasé classe 36KV
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <a href="/transformateur_pui_triph">
                                      Transformateur de PUISSANCE Triphasé
                                    </a>
                                    {/* drop down third level */}
                                  </li>
                                  <li>
                                    <a href="/transformateur_mono">
                                      Transformateurs Monophasés
                                    </a>
                                    {/* drop down third level */}
                                  </li>
                                  <li>
                                    <a href="/transformateur_special">
                                      Transformateurs Spéciaux
                                    </a>
                                    {/* drop down third level */}
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="/tableauMT-BT">
                                  Tableau MT/BT
                                  <i className="ti-plus fa-indicator" />
                                </a>
                                {/* drop down second level */}
                                <ul className="drop-down-multilevel right-side">
                                  <li>
                                    <a href="/tableauais">Tableau-AIS 36KV</a>
                                    {/* drop down third level */}
                                  </li>
                                  <li>
                                    <a href="Coffret_Protection_Transformateur">
                                      Coffret Protection Transformateur
                                    </a>
                                    {/* drop down third level */}
                                  </li>
                                  <li>
                                    <a href="/eems">
                                      Système de Gestion de l'Energie
                                    </a>
                                    {/* drop down third level */}
                                  </li>
                                  <li>
                                    <a href="/batterie_condencateur">
                                      Batterie de condensateurs
                                    </a>
                                    {/* drop down third level */}
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a>SERVICES </a>
                            <ul className="drop-down-multilevel left-side">
                              <li>
                                {" "}
                                <a href="/services">SAV & Réparation</a>{" "}
                              </li>
                              <li>
                                <a href="/training">Formation</a>
                              </li>
                            </ul>
                            {/* drop down full width */}
                          </li>

                          <li>
                            <a href="/nouscontacter">NOUS CONTACTER</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </nav>
            {/* menu end */}
          </centre>
        </div>
      </header>
    </div>
  );
};

export default HeaderHome;
