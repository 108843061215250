import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Loader from "./Loader";
import Header from "./Header";
import styled from "styled-components";

const Transformateur_pui_TRIPH = () => {
  const StyledHeading = styled.h6`
    position: relative;
    font-size: 100px;
    color: #008bcd;

    &::before {
      content: "—";
      font-size: inherit;
      color: #008bcd;
      display: block;
    }
  `;
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          style={{
            backgroundImage:
              "url(/assets/images/transformateur/Triphase/slider-triphase-36-24.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>SACEM INDUSTRIES</h1>
                  <p>innovative Technologies to Bring Electricity</p>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home" /> Accueil
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>Transformateur de PUISSANCE Triphasé</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
 feature */}
        <section className="service white-bg page-section-ptb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect">
                    Transformateur de PUISSANCE Triphasé
                  </h2>
                </div>
              </div>
            </div>
            {/* ============================================ */}
            {/*=====================================*/}
            {/**<section className="page-section-ptb"> */}
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 mb-30 ">
                    {/** */}
                    <div
                      className="rotateInDownLeft wow"
                      style={{
                        visibility: "visible",
                        animationName: "rotateInDownLeft",
                      }}
                    >
                      <div className="media border p-4">
                        <div className="feature-icon media-icon mr-4">
                          <span className="ti-settings theme-color1" />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">
                            CARACTÉRISTIQUES GÉNÉRALES
                          </h5>
                          <br />
                          <p className="mb-0">
                            Transformateur triphasé immergé dans l’huile
                          </p>
                          <p className="mb-0">Type respirant</p>
                          <p className="mb-0">
                            Refroidissement naturel type ONAN /ONAF
                          </p>
                          <p className="mb-0">
                            Installation intérieure/extérieure
                          </p>
                          <p className="mb-0">
                            Couleur:RAL7035 (autre sur demande)
                          </p>
                          <p className="mb-0">Norme CEI 60076 </p>
                          <br />
                          <br />
                          <br />
                        </div>
                      </div>
                    </div>
                    {/** */}
                  </div>
                  <div className="col-lg-6 col-sm-6 mb-30 ">
                    {/** */}
                    <div
                      className="rotateInDownRight wow"
                      style={{
                        visibility: "visible",
                        animationName: "rotateInDownRight",
                      }}
                    >
                      <div className="media border p-4">
                        <div className="feature-icon media-icon mr-4">
                          <span className="ti-bolt theme-color1" />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">TENSIONS DE SERVICES</h5>
                          <br />
                          <p className="mb-0">Classe:24 &amp; 36 kV</p>
                          <p className="mb-0">15 kV, 20 kV, 31,5 kV</p>
                          <p className="mb-0">BT:5,5 kV, 11 kV, 15 kV</p>
                          <p className="mb-0">Couplage:Dyn11, YNd11, YNyn0</p>
                          <p className="mb-0">Fréquence:50HZ</p>
                          <p className="mb-0">Conducteur:Cuivre </p>
                          <p className="mb-0">
                            DIÉLECTRIQUE LIQUIDE:Huile Minérale isolante selon
                            CEI 60296
                          </p>
                          <br />
                          <br />
                        </div>
                      </div>
                    </div>
                    {/** */}
                  </div>
                  <div className="col-lg-6 col-sm-6 xs-mb-30">
                    {/** */}
                    <div
                      className="rotateInUpLeft wow"
                      style={{
                        visibility: "visible",
                        animationName: "rotateInUpLeft",
                      }}
                    >
                      <div className="media border p-4">
                        <div className="feature-icon media-icon mr-4">
                          <span className="ti-package theme-color1" />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">ÉQUIPEMENTS DE BASE </h5>
                          <br />
                          <p className="mb-0">1- Radiateurs demontables</p>
                          <p className="mb-0">2- Conservateur d'huile</p>
                          <p className="mb-0">
                            3- Orifice de remplissage conservateur
                          </p>
                          <p className="mb-0">4- Assécheur d'air</p>
                          <p className="mb-0">5- Coffret de regroupement</p>
                          <p className="mb-0">6- Vanne vidange conservateur</p>
                          <p className="mb-0">7- Prises de mise à la terre</p>
                          <p className="mb-0">8- Vanne de traitement</p>
                          <p className="mb-0">9- Soupape de sureté</p>
                          <p className="mb-0">
                            10- Traversées en porcelaine ou embrochables
                          </p>
                          <p className="mb-0">11- Relais Buchholz</p>
                          <p className="mb-0">
                            12- Indicateur de niveau d'huile
                          </p>
                          <p className="mb-0">13- Thermometre à cadran</p>
                          <p className="mb-0">14- Plaque signalétique </p>
                          <p className="mb-0">15- Vanne de vidange</p>
                          <p className="mb-0">16- Galet de roulement</p>
                          <p className="mb-0">17- Doigt de gant</p>
                          <p className="mb-0">
                            18- Commutateur de variation (en charge et hors
                            charge)
                          </p>
                          <p className="mb-0">19- Anneau de levage </p>
                          <p className="mb-0">20- Bypass</p>
                        </div>
                      </div>
                    </div>
                    {/** */}
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    {/** */}
                    <div
                      className="rotateInUpRight wow"
                      style={{
                        visibility: "visible",
                        animationName: "rotateInUpRight",
                      }}
                    >
                      <div className="media border p-4">
                        <div className="feature-icon media-icon mr-4">
                          <span className="ti-list theme-color1" />
                        </div>
                        <div className="media-body">
                          <h5 className="text-back">ÉQUIPEMENTS OPTIONNELS</h5>
                          <br />
                          <p className="mb-0">Clapet de retenu d'huile</p>
                          <p className="mb-0">Thermometre des enroulements</p>
                          <p className="mb-0">
                            Note : les options ci-dessus ne sont pas
                            limitatives.
                          </p>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/** */}
                </div>
              </div>
              <div>
                {/*=================================
 Tableau */}
                <section id="tab36" className="page-section-ptb data-table">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <h3 style={{ color: "#00356a" }}>
                          CARACTERISTIQUES ELECTRIQUES{" "}
                        </h3>
                        <StyledHeading className="h6-line"></StyledHeading>
                        <br />
                        <div className="table-responsive">
                          <table className="table table-1 table-bordered table-striped">
                            <thead>
                              <tr>
                                <td colSpan={3}>
                                  Puissance {"{"}kVA{"}"}
                                </td>
                                <td>4000</td>
                                <td>5000</td>
                                <td>6300</td>
                                <td>8000</td>
                                <td>10000</td>
                                <td>12000</td>
                                <td>15000</td>
                                <td>20000</td>
                                <td>25000</td>
                                <td>26000</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan={3}>
                                  Pertes à vide {"{"}W{"}"}
                                </td>
                                <td>5000</td>
                                <td>6000</td>
                                <td>6800</td>
                                <td>9200</td>
                                <td>10000</td>
                                <td>11600</td>
                                <td>13000</td>
                                <td>14000</td>
                                <td>18500</td>
                                <td>20000</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  Pertes en charge {"{"}W{"}"}
                                </td>
                                <td>42000</td>
                                <td>50000</td>
                                <td>52000</td>
                                <td>57000</td>
                                <td>65000</td>
                                <td>76000</td>
                                <td>85000</td>
                                <td>96000</td>
                                <td>115000</td>
                                <td>150000</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  Courant à vide {"{"}%{"}"}
                                </td>
                                <td>2.5</td>
                                <td>2.3</td>
                                <td>2.2</td>
                                <td>2</td>
                                <td>1.9</td>
                                <td>1.8</td>
                                <td>1.7</td>
                                <td>1.6</td>
                                <td>1.5</td>
                                <td>1.2</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  Impedance de court circuit {"{"}%{"}"}
                                </td>
                                <td>8</td>
                                <td>8</td>
                                <td>8</td>
                                <td>8</td>
                                <td>9</td>
                                <td>9</td>
                                <td>10</td>
                                <td>10</td>
                                <td>10</td>
                                <td>10</td>
                              </tr>
                              <tr>
                                <td>
                                  Chute de tension {"{"}%{"}"}
                                </td>
                                <td colSpan={2}>Cos φ= 0.8</td>
                                <td>4.88</td>
                                <td>5.37</td>
                                <td>5.43</td>
                                <td>5.35</td>
                                <td>5.90</td>
                                <td>5.89</td>
                                <td>6.54</td>
                                <td>6.38</td>
                                <td>6.66</td>
                                <td>6.41</td>
                              </tr>
                              <tr>
                                <td>
                                  Chute de tension {"{"}%{"}"}
                                </td>
                                <td colSpan={2}>Cos φ= 1</td>
                                <td>0.10</td>
                                <td>0.73</td>
                                <td>0.82</td>
                                <td>0.71</td>
                                <td>0.65</td>
                                <td>0.63</td>
                                <td>0.70</td>
                                <td>0.48</td>
                                <td>0.96</td>
                                <td>1.03</td>
                              </tr>
                              <tr>
                                <td>
                                  Rendement {"{"}%{"}"} (à Cos φ=0.8)
                                </td>
                                <td>Charge en %</td>
                                <td>50</td>
                                <td>99.90</td>
                                <td>99.23</td>
                                <td>99.22</td>
                                <td>99.27</td>
                                <td>99.35</td>
                                <td>99.37</td>
                                <td>99.37</td>
                                <td>99.53</td>
                                <td>99.53</td>
                                <td>99.45</td>
                              </tr>
                              <tr>
                                <td>
                                  Rendement {"{"}%{"}"} (à Cos φ=0.8)
                                </td>
                                <td>Charge en %</td>
                                <td>75</td>
                                <td>99.88</td>
                                <td>99.11</td>
                                <td>99.06</td>
                                <td>99.15</td>
                                <td>99.23</td>
                                <td>99.25</td>
                                <td>99.22</td>
                                <td>99.44</td>
                                <td>99.45</td>
                                <td>99.34</td>
                              </tr>
                              <tr>
                                <td>
                                  Rendement {"{"}%{"}"} (à Cos φ=0.8)
                                </td>
                                <td>Charge en %</td>
                                <td>100</td>
                                <td>99.85</td>
                                <td>98.93</td>
                                <td>98.85</td>
                                <td>98.98</td>
                                <td>99.07</td>
                                <td>99.10</td>
                                <td>99.03</td>
                                <td>99.32</td>
                                <td>99.34</td>
                                <td>99.19</td>
                              </tr>
                            </tbody>
                          </table>
                          {/*=================================
 Tableau */}
                          <br />
                          <br />
                          <br />
                          <h3 style={{ color: "#00356a" }}>
                            DIMENSIONS ET POIDS
                          </h3>
                          <StyledHeading className="h6-line"></StyledHeading>
                          <br />
                          <table className="table table-1 table-bordered table-striped">
                            <thead>
                              <tr>
                                <td colSpan={3}>Puissance (kVA)</td>
                                <td>4000</td>
                                <td>5000</td>
                                <td>6300</td>
                                <td>8000</td>
                                <td>10000</td>
                                <td>12000</td>
                                <td>15000</td>
                                <td>20000</td>
                                <td>25000</td>
                                <td>26000</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan={3}>L</td>
                                <td>3050</td>
                                <td>3400</td>
                                <td>4000</td>
                                <td>4520</td>
                                <td>4650</td>
                                <td>4800</td>
                                <td>4900</td>
                                <td>5150</td>
                                <td>4270</td>
                                <td>4300</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>I</td>
                                <td>2560</td>
                                <td>2750</td>
                                <td>2700</td>
                                <td>3080</td>
                                <td>3120</td>
                                <td>3200</td>
                                <td>3650</td>
                                <td>4000</td>
                                <td>3490</td>
                                <td>4500</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>H</td>
                                <td>3000</td>
                                <td>3250</td>
                                <td>3520</td>
                                <td>3600</td>
                                <td>3900</td>
                                <td>4200</td>
                                <td>4400</td>
                                <td>4800</td>
                                <td>3870</td>
                                <td>5000</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>E</td>
                                <td>1000</td>
                                <td>1000</td>
                                <td>1000</td>
                                <td>1200</td>
                                <td>1200</td>
                                <td>1200</td>
                                <td>1500</td>
                                <td>1500</td>
                                <td>1500</td>
                                <td>1500</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>Poids Huile (Kg)</td>
                                <td>2200</td>
                                <td>2850</td>
                                <td>3200</td>
                                <td>4000</td>
                                <td>4900</td>
                                <td>6000</td>
                                <td>8550</td>
                                <td>10300</td>
                                <td>8450</td>
                                <td>10700</td>
                              </tr>
                              <tr>
                                <td colSpan={3}>Poids Total (kg)</td>
                                <td>9500</td>
                                <td>12300</td>
                                <td>14000</td>
                                <td>18500</td>
                                <td>22000</td>
                                <td>29000</td>
                                <td>33500</td>
                                <td>42000</td>
                                <td>41000</td>
                                <td>46500</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="entry-button" style={{ marginLeft: 20 }}>
                    <a className="button button-border" href="/contact">
                      Nous Contacter
                    </a>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </section>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Transformateur_pui_TRIPH;
