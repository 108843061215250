import React from "react";

const Commerciaux = () => {
  return (
    <div>
      <div>
        {/*=================================
page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          data-jarallax='{"speed": 0.6}'
          style={{ backgroundImage: "url(images/bg/02.jpg)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>contact</h1>
                  <p />
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home" />
                      Accueil
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>contact</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
 team */}
        <section className="page-section-ptb">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="mb-30">Contact</h4>
              </div>
              <div className="col-lg-3 col-sm-6 sm-mb-30">
                <div className="team">
                  <div className="team-photo">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/Commerciaux/hbaili_nizar.png"
                      alt
                    />
                  </div>
                  <div className="team-description">
                    <div className="team-info">
                      <h5>
                        <a href="#"> Nizar HBAILI</a>
                      </h5>
                      <span>Afrique</span>
                    </div>
                    <div className="team-contact">
                      <span className="call"> +225 07 47 12 76 86</span>
                      <span className="email">
                        {" "}
                        <i className="fa fa-envelope-o" />{" "}
                        nizar.hbaili@sacemgroup.com
                      </span>
                    </div>
                    <div className="social-icons color clearfix">
                      <ul>
                        <li className="social-facebook">
                          <a href="https://www.facebook.com/sacemindustries">
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        <li className="social-linkedin">
                          <a href="https://www.linkedin.com/profile/view?id=366253326&trk=spm_pic">
                            <i className="fa fa-linkedin" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 sm-mb-30">
                <div className="team">
                  <div className="team-photo">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/Commerciaux/oussema_kaabi.png"
                      alt
                    />
                  </div>
                  <div className="team-description">
                    <div className="team-info">
                      <h5>
                        <a href="#"> Oussama KAABI</a>
                      </h5>
                      <span>Algerie</span>
                    </div>
                    <div className="team-contact">
                      <span className="call"> +216 29 493 151</span>
                      <span className="email">
                        {" "}
                        <i className="fa fa-envelope-o" />{" "}
                        oussama.kaabi@sacemgroup.com
                      </span>
                    </div>
                    <div className="social-icons color clearfix">
                      <ul>
                        <li className="social-facebook">
                          <a href="https://www.facebook.com/sacemindustries">
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        <li className="social-linkedin">
                          <a href="https://www.linkedin.com/profile/view?id=366253326&trk=spm_pic">
                            <i className="fa fa-linkedin" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 xs-mb-30">
                <div className="team">
                  <div className="team-photo">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/Commerciaux/zaaleni_rahma.png"
                      alt
                    />
                  </div>
                  <div className="team-description">
                    <div className="team-info">
                      <h5>
                        <a href="#"> Rahma ZAALENI</a>
                      </h5>
                      <span>Tunis</span>
                    </div>
                    <div className="team-contact">
                      <span className="call"> +216 29 591 207</span>
                      <span className="email">
                        {" "}
                        <i className="fa fa-envelope-o" />{" "}
                        Rahma.ZAALANI@sacemgroup.com
                      </span>
                    </div>
                    <div className="social-icons color clearfix">
                      <ul>
                        <li className="social-facebook">
                          <a href="https://www.facebook.com/sacemindustries">
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        <li className="social-linkedin">
                          <a href="https://www.linkedin.com/profile/view?id=366253326&trk=spm_pic">
                            <i className="fa fa-linkedin" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="team">
                  <div className="team-photo">
                    <img
                      className="img-fluid mx-auto"
                      src="/assets/images/Commerciaux/nasser_abdulhamid.png"
                      alt
                    />
                  </div>
                  <div className="team-description">
                    <div className="team-info">
                      <h5>
                        <a href="#"> Abdulhamid NASSER</a>
                      </h5>
                      <span>Lybia</span>
                    </div>
                    <div className="team-contact">
                      <span className="call"> +216 29 922 626</span>
                      <span className="email">
                        {" "}
                        <i className="fa fa-envelope-o" />{" "}
                        abdulhamid.nassr@sacemgroup.com
                      </span>
                    </div>
                    <div className="social-icons color clearfix">
                      <ul>
                        <li className="social-facebook">
                          <a href="https://www.facebook.com/sacemindustries">
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        <li className="social-linkedin">
                          <a href="https://www.linkedin.com/profile/view?id=366253326&trk=spm_pic">
                            <i className="fa fa-linkedin" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <br />
        {/*=================================
 team */}
      </div>
    </div>
  );
};

export default Commerciaux;
