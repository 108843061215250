import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Loader from "./Loader";

const Coffret = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          data-jarallax='{"speed": 0.6}'
          style={{ backgroundImage: "url(/assets/images/slidebat.png)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>Coffret Protection Transformateur</h1>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home" /> Accueil
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>Coffret Protection Transformateur</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
 Premium Features */}
        <section className="service white-bg page-section-ptb">
          <div className="container">
            <div style={{ textAlign: "end" }}>
              {/*<a className="button" href="#">
                Nous Contacter1 <i className="fa fa-angle-right" />
              </a>*/}
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect" style={{ color: "#00356a" }}>
                    Coffret Protection Transformateur
                  </h2>
                  <br />
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="who-we-are-left">
                      <div
                        className="owl-carousel"
                        data-nav-dots="true"
                        data-items={1}
                        data-md-items={1}
                        data-sm-items={1}
                        data-xs-items={1}
                        data-xx-items={1}
                      >
                        <div className="item">
                          <img
                            className="img-fluid full-width"
                            src="/assets/images/CP1T.png"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid full-width"
                            src="/assets/images/CP2T.png"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid full-width"
                            src="/assets/images/cp1T open.png"
                            alt
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 sm-mt-30">
                    {/** */}
                    <div
                      className="bounceIn wow"
                      style={{
                        visibility: "visible",
                        animationName: "bounceIn",
                      }}
                    >
                      <div className="section-title">
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <p>
                          Le coffret de protection de transformateur est destiné
                          à assurer la surveillance et la protection des
                          transformateurs immergés par un déclenchement amont et
                          aval des appareils de protection qui y sont associés
                        </p>
                        <div className="row mt-30">
                          <div className="col-md-6">
                            <ul className="list list-arrow">
                              <li>
                                {" "}
                                Coffret protection un transformateur CP1T
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul className="list list-arrow">
                              <li>
                                {" "}
                                Coffret protection deux transformateurs CP2T
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row mt-30">
                          <div className="col-md-6">
                            <ul className="list list-arrow">
                              <li>
                                {" "}
                                Coffret protection trois transformateurs CP3T
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul className="list list-arrow">
                              <li>
                                {" "}
                                Coffret protection quatre transformateurs CP4T
                              </li>
                            </ul>
                          </div>
                        </div>
                        <br />
                        <ul
                          className="list list-unstyled list-hand"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <li> Avec relais DMCR et DGPT2</li>
                          <li>Avec relais BUCCHOLZ</li>
                        </ul>

                        <br />
                        <div style={{ textAlign: "center" }}>
                          <a className="button button-border" href="/contact">
                            Nous Contacter
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <div className="feature-text left-icon mt-50 xs-mt-20">
                        <div className="feature-icon">
                          <span
                            className="ti-desktop theme-color"
                            aria-hidden="true"
                          />
                        </div>

                        <div className="feature-info">
                          <h5 className="text-back">
                            Protection Fiable des Transformateurs
                          </h5>
                          <div
                            className="fadeInLeft wow"
                            style={{
                              visibility: "visible",
                              animationName: "fadeInLeft",
                            }}
                          >
                            <p>
                              Les coffrets CPT sont conçus pour protéger les
                              transformateurs contre divers dangers tels que les
                              surcharges,l'échauffements.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <div className="feature-text left-icon mt-50 xs-mt-20">
                        <div className="feature-icon">
                          <span
                            className="ti-server theme-color"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="feature-info">
                          <h5 className="text-back">
                            Fiabilité et Durabilité des Installations
                          </h5>
                          <div
                            className="fadeInUp wow"
                            style={{
                              visibility: "visible",
                              animationName: "fadeInUp",
                            }}
                          >
                            <p>
                              Les coffrets CPT réduisent les coûts de
                              maintenance et prolongent la durée de vie des
                              transformateurs, permettant ainsi une meilleure
                              rentabilité pour les clients.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <div className="feature-text left-icon mt-50 xs-mt-20">
                        <div className="feature-icon">
                          <span
                            className="ti-light-bulb theme-color"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="feature-info">
                          <h5 className="text-back">
                            Efficacité Énergétique et Optimisation des
                            Performances
                          </h5>
                          <div
                            className="fadeInRight wow"
                            style={{
                              visibility: "visible",
                              animationName: "fadeInRight",
                            }}
                          >
                            <p>
                              Des coffrets bien conçus permettent également une
                              meilleure gestion thermique des transformateurs,
                              en facilitant la dissipation de chaleur et en
                              minimisant les pertes d’énergie. Une mauvaise
                              qualité des coffrets peut entraîner une surchauffe
                              des composants, ce qui affecte l’efficacité
                              globale du transformateur et augmente les coûts
                              énergétiques
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section className="page-section-ptb"></section>
              {/* ============================================ */}
              {/*=================================Premium Features */}
              {/*=================================Work Process  */}
              <section>
                <div className="row">
                  <div className="col-lg-3 col-sm-6">
                    <div className="product xs-mb-30">
                      <div className="product-image">
                        <img
                          className="img-fluid mx-auto"
                          src="/assets/images/CP1T.png"
                          alt
                        />
                        <div className="product-overlay">
                          <h5>Coffret de Protection un Transformateur</h5>
                          <br />
                          <br />
                          <a className="button button-border" href="#">
                            Catalogue
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="product">
                      <div className="product-image">
                        <img
                          className="img-fluid mx-auto"
                          src="/assets/images/CP2T.png"
                          alt
                        />
                        <div className="product-overlay">
                          <h5>Coffret de Protection deux Transformateurs</h5>
                          <br />
                          <br />
                          <a className="button button-border" href="#">
                            Catalogue
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 sm-mt-40">
                    <div className="offer-banner-1 text-center">
                      <div>
                        <div className="line-effect">
                          <img
                            className="img-fluid"
                            src="/assets/images/cp1T open.png"
                            alt
                          />
                          <div className="overlay" />
                        </div>
                      </div>
                      <div className="banner-content">
                        <h1 className="uppercase text-white">
                          brand discount !
                        </h1>
                        <strong className="text-white">
                          Having more than 5000+ New Exclusive Men &amp; Women
                          products
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Coffret;
