import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Loader from "./Loader";
import styled from "styled-components";

const Tableauais = () => {
  const StyledHeading = styled.h6`
    position: relative;
    font-size: 100px;
    color: #008bcd;

    &::before {
      content: "—";
      font-size: inherit;
      color: #008bcd;
      display: block;
    }
  `;
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          style={{ backgroundImage: "url(/assets/images/slidebat.png)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>Tableau-AIS</h1>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home" /> Accueil
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>Tableau-AIS</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
 feature */}

        <section
          id="presentation"
          style={{ paddingBottom: "80px", paddingTop: "100px" }}
        >
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center">
                <h2 className="title-effect" style={{ color: "#00356a" }}>
                  Cellule AIS sous enveloppe métallique type SME 36 KV
                </h2>
                <br />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="who-we-are-left">
                  <div
                    className="owl-carousel"
                    data-nav-dots="true"
                    data-items={1}
                    data-md-items={1}
                    data-sm-items={1}
                    data-xs-items={1}
                    data-xx-items={1}
                  >
                    <div className="item">
                      <img
                        className="img-fluid full-width"
                        src="/assets/images/cellule trio.png"
                        alt="ais1"
                      />
                    </div>
                    <div className="item">
                      <img
                        className="img-fluid full-width"
                        src="/assets/images/cellule trio.png"
                        alt="ais2"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 sm-mt-30">
                {/** */}
                <div
                  className="bounceIn wow"
                  style={{
                    visibility: "visible",
                    animationName: "bounceIn",
                  }}
                >
                  <p>
                    Nos cellules moyenne tension 36 kV modulaires, équipées
                    d'interrupteurs sous SF6 et de jeux de barres dans l'air,
                    sont fabriquées conformément à la norme internationale
                    IEC-62271-200. Homologuées par la STEG et déjà installées
                    dans le réseau Tunisien.
                  </p>
                  <br />
                  <ul
                    className="list list-unstyled list-hand"
                    style={{ borderRight: "0px solid white" }}
                  >
                    <li>SME01 : Cellule réseau (Arrivée/Départ)</li>
                    <li>
                      SME02 : Cellule de protection transformateur par fusible
                    </li>
                    <li>SME03 : Cellule de mesure tension</li>
                    <li>
                      SME05 : Cellule disjoncteur double sectionnement (cellule
                      de couplage)
                    </li>
                  </ul>
                  <br />
                  <div style={{ textAlign: "center" }}>
                    <a className="button button-border" href="/contact">
                      Nous Contacter
                    </a>
                  </div>
                </div>
                {/** */}
              </div>
            </div>
          </div>
        </section>
        <section id="tab36" className=" data-table">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <h3 style={{ color: "#00356a" }}>
                  Specification Technique SME 36 KV
                </h3>
                <StyledHeading className="h6-line"></StyledHeading>
                <br />
                <div className="table-responsive">
                  <table className="table table-1 table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Tension Nominal (KV)</th>
                        <th>Rated Voltage (KV)</th>
                        <th>36</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>NIveau d'isolement assigné</td>
                        <td>Insulation level</td>
                        <td />
                      </tr>
                      <tr>
                        <td>Isolement (50HZ, 1min KV eff)</td>
                        <td>Insulation level 50HZ, 1min</td>
                        <td>70</td>
                      </tr>
                      <tr>
                        <td>Sectionnement (50HZ, 1min KV eff)</td>
                        <td>Isolation level 50HZ, 1min</td>
                        <td>80</td>
                      </tr>
                      <tr>
                        <td>Isolement (1.2/50µs)</td>
                        <td>Insulation level 1.2/50µs</td>
                        <td>170</td>
                      </tr>
                      <tr>
                        <td>Sectionnement (1.2/50µs)</td>
                        <td>Isolation level 1.2/50µs</td>
                        <td>195</td>
                      </tr>
                      <tr>
                        <td>Courant de courte durée</td>
                        <td>Short time withstand current</td>
                        <td />
                      </tr>
                      <tr>
                        <td>Valeur efficace (KA 1s)</td>
                        <td>r.m.s value</td>
                        <td>16</td>
                      </tr>
                      <tr>
                        <td>Valeur créte (KAc)</td>
                        <td>peak value</td>
                        <td>40</td>
                      </tr>
                      <tr>
                        <td>Courant assigne de la cellule (A)</td>
                        <td>Rated current (A)</td>
                        <td>630</td>
                      </tr>
                      <tr>
                        <td>Frequence (Hz)</td>
                        <td>Frequency (Hz)</td>
                        <td>50</td>
                      </tr>
                      <tr>
                        <td>Tenue a l'arc interne (KA/1s)</td>
                        <td>Internal Arc Withstand (KA/1s)</td>
                        <td>16</td>
                      </tr>
                      <tr>
                        <td>
                          Pouvoir de fermeture du sectionneur a la terre (KA)
                        </td>
                        <td>Earting switch Making Capacity (KA)</td>
                        <td>40</td>
                      </tr>
                      <tr>
                        <td>Degre de protection de l'enveloppe principale</td>
                        <td>IP ddgree</td>
                        <td>IP3X</td>
                      </tr>
                      <tr>
                        <td>Indice de protection mecanique</td>
                        <td>IK</td>
                        <td>IK07</td>
                      </tr>
                      <tr>
                        <td>Milieu de coupure</td>
                        <td>Breaking</td>
                        <td>SF6</td>
                      </tr>
                      <tr>
                        <td>Milieu d'isolement</td>
                        <td>Busbar Insulation</td>
                        <td>AIR</td>
                      </tr>
                      <tr>
                        <td>Endutance mecanique</td>
                        <td>Mechanical endurance</td>
                        <td>1000</td>
                      </tr>
                      <tr>
                        <td>Classe interrupteur</td>
                        <td>LBS Class</td>
                        <td>E3</td>
                      </tr>
                      <tr>
                        <td>Perte de continuite de service</td>
                        <td>Loss of service Continuity</td>
                        <td>LSC2A</td>
                      </tr>
                      <tr>
                        <td>Classification arc interne IAC</td>
                        <td>IAC Internal arc classification</td>
                        <td>16KA, 1s, AFL</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <br />
                  <div style={{ textAlign: "end" }}>
                    <a className="button button-border" href="#">
                      Catalogue
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="configuration" style={{ paddingBottom: "80px" }}>
          <div className="container">
            <div className="row mt-60">
              <div className="col-lg-12 col-md-12">
                <h4 className="mb-30">Configuration</h4>
                <div className="isotope columns-3 popup-gallery no-title">
                  <div className="grid-item">
                    <div className="portfolio-item">
                      <img src="/assets/images/sme/SME01.jpg" alt="" />
                      {/*   <div class="portfolio-overlay">
                 <h4 class="text-white"> <a href="portfolio-single-01.html"> Post item image</a> </h4>
               <span class="text-white"> <a href="#"> Photography  </a>| <a href="#">Illustration </a> </span>  
               </div> */}
                      <a
                        className="popup portfolio-img"
                        href="/assets/images/sme/bigsme01.png"
                      >
                        <i className="fa fa-arrows-alt" />
                      </a>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="portfolio-item">
                      <img src="/assets/images/sme/SME02.jpg" alt="" />
                      {/*    <div class="portfolio-overlay">
                 <h4 class="text-white"> <a href="portfolio-single-01.html"> Post item image</a> </h4>
               <span class="text-white"> <a href="#"> Photography  </a>| <a href="#">Illustration </a> </span>  
               </div> */}
                      <a
                        className="popup portfolio-img"
                        href="/assets/images/sme/bigsme02.png"
                      >
                        <i className="fa fa-arrows-alt" />
                      </a>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="portfolio-item">
                      <img src="/assets/images/sme/SME03.jpg" alt="" />
                      {/*  <div class="portfolio-overlay">
                 <h4 class="text-white"> <a href="portfolio-single-01.html"> Post item image</a> </h4>
               <span class="text-white"> <a href="#"> Photography  </a>| <a href="#">Illustration </a> </span>  
               </div> */}
                      <a
                        className="popup portfolio-img"
                        href="/assets/images/sme/bigsme03.png"
                      >
                        <i className="fa fa-arrows-alt" />
                      </a>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="portfolio-item">
                      <img src="/assets/images/sme/SME04.jpg" alt="" />
                      {/*    <div class="portfolio-overlay">
                 <h4 class="text-white"> <a href="portfolio-single-01.html"> Post item image</a> </h4>
               <span class="text-white"> <a href="#"> Photography  </a>| <a href="#">Illustration </a> </span>  
               </div> */}
                      <a
                        className="popup portfolio-img"
                        href="/assets/images/sme/bigsme04.png"
                      >
                        <i className="fa fa-arrows-alt" />
                      </a>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="portfolio-item">
                      <img src="/assets/images/sme/SME05.jpg" alt="" />
                      {/*     <div class="portfolio-overlay">
                 <h4 class="text-white"> <a href="portfolio-single-01.html"> Post item image</a> </h4>
               <span class="text-white"> <a href="#"> Photography  </a>| <a href="#">Illustration </a> </span>  
               </div> */}
                      <a
                        className="popup portfolio-img"
                        href="/assets/images/sme/bigsme05.png"
                      >
                        <i className="fa fa-arrows-alt" />
                      </a>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="portfolio-item">
                      <img src="/assets/images/sme/SME06.jpg" alt="" />
                      {/*   <div class="portfolio-overlay">
                 <h4 class="text-white"> <a href="portfolio-single-01.html"> Post item image</a> </h4>
               <span class="text-white"> <a href="#"> Photography  </a>| <a href="#">Illustration </a> </span>  
               </div> */}
                      <a
                        className="popup portfolio-img"
                        href="/assets/images/sme/bigsme06.png"
                      >
                        <i className="fa fa-arrows-alt" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="isotope columns-3 popup-gallery no-title">
                  <div className="grid-item">
                    <div className="portfolio-item">
                      <img src="/assets/images/sme/SME07.jpg" alt="" />
                      {/*   <div class="portfolio-overlay">
                 <h4 class="text-white"> <a href="portfolio-single-01.html"> Post item image</a> </h4>
               <span class="text-white"> <a href="#"> Photography  </a>| <a href="#">Illustration </a> </span>  
               </div> */}
                      <a
                        className="popup portfolio-img"
                        href="/assets/images/sme/bigsme07.png"
                      >
                        <i className="fa fa-arrows-alt" />
                      </a>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="portfolio-item">
                      <img src="/assets/images/sme/SME08.jpg" alt="" />
                      {/*    <div class="portfolio-overlay">
                 <h4 class="text-white"> <a href="portfolio-single-01.html"> Post item image</a> </h4>
               <span class="text-white"> <a href="#"> Photography  </a>| <a href="#">Illustration </a> </span>  
               </div> */}
                      <a
                        className="popup portfolio-img"
                        href="/assets/images/sme/bigsme08.png"
                      >
                        <i className="fa fa-arrows-alt" />
                      </a>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="portfolio-item">
                      <img src="/assets/images/sme/SME09.jpg" alt="" />
                      {/*  <div class="portfolio-overlay">
                 <h4 class="text-white"> <a href="portfolio-single-01.html"> Post item image</a> </h4>
               <span class="text-white"> <a href="#"> Photography  </a>| <a href="#">Illustration </a> </span>  
               </div> */}
                      <a
                        className="popup portfolio-img"
                        href="/assets/images/sme/bigsme09.png"
                      >
                        <i className="fa fa-arrows-alt" />
                      </a>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="portfolio-item">
                      <img src="/assets/images/sme/SME10.jpg" alt="" />
                      {/*    <div class="portfolio-overlay">
                 <h4 class="text-white"> <a href="portfolio-single-01.html"> Post item image</a> </h4>
               <span class="text-white"> <a href="#"> Photography  </a>| <a href="#">Illustration </a> </span>  
               </div> */}
                      <a
                        className="popup portfolio-img"
                        href="/assets/images/sme/bigsme10.png"
                      >
                        <i className="fa fa-arrows-alt" />
                      </a>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="portfolio-item">
                      <img src="/assets/images/sme/SME11.jpg" alt="" />
                      {/*     <div class="portfolio-overlay">
                 <h4 class="text-white"> <a href="portfolio-single-01.html"> Post item image</a> </h4>
               <span class="text-white"> <a href="#"> Photography  </a>| <a href="#">Illustration </a> </span>  
               </div> */}
                      <a
                        className="popup portfolio-img"
                        href="/assets/images/sme/bigsme11.png"
                      >
                        <i className="fa fa-arrows-alt" />
                      </a>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="portfolio-item">
                      <img src="/assets/images/sme/SME12.jpg" alt="" />
                      {/*   <div class="portfolio-overlay">
                 <h4 class="text-white"> <a href="portfolio-single-01.html"> Post item image</a> </h4>
               <span class="text-white"> <a href="#"> Photography  </a>| <a href="#">Illustration </a> </span>  
               </div> */}
                      <a
                        className="popup portfolio-img"
                        href="/assets/images/sme/bigsme12.png"
                      >
                        <i className="fa fa-arrows-alt" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
 feature */}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Tableauais;
