import React from "react";

const Loader = ({ isVisible }) => {
  return (
    <div style={{ display: !isVisible ? "block" : "none" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
        }}
      >
        <img
          style={{ height: "225px" }}
          src="/assets/images/pre-loader/loader.gif"
          alt="Loading..."
        />
      </div>
    </div>
  );
};

export default Loader;
