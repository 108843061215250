import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import Presentation from "./Components/Presentation";
import Maps from "./Components/Maps";
import Sav from "./Components/Sav";
import Team from "./Components/Team";
import Training from "./Components/Training";
import Filtreactive from "./Components/Filtreactive";
import Produits from "./Components/Produits";
import Contact from "./Components/Contact";
import Coffret from "./Components/Coffret";
import Faq from "./Components/Faq";
import Tableauais from "./Components/Tableauais";
import Form from "./Components/Form";
import Certificats from "./Components/Certificats";
import Commerciaux from "./Components/Commerciaux";
import Transformateur_DIST_24 from "./Components/Transformateur_DIST_24";
import Transformateur_DIST_36 from "./Components/Transformateur_DIST_36";
import Transformateur_Mono from "./Components/Transformateur_Mono";
import Transformateur_pui_TRIPH from "./Components/Transformateur_pui_TRIPH";
import Transformateur_Special from "./Components/Transformateur_Special";
import Transformateur from "./Components/Transformateur";
import Nouscontacter from "./Components/Nouscontacter";
import Rhcarriers from "./Components/Rhcarriers";
import Eems from "./Components/Eems";
import Fortest2 from "./Components/Fortest2";
import Batterie_Condencateur from "./Components/Batterie_Condencateur";
import Fs from "./Components/Fs";
import Actualites from "./Components/Actualites";
import Historique from "./Components/Historique";
import Power from "./Components/Power";
function App() {
  return (
    <div className="App">
      {/**loader */}

      <div>
        <div>
          {/**<Header /> */}

          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/presentation" element={<Presentation />} />
              <Route path="/maps" element={<Maps />} />
              {/** <Route path="/histoire" element={<Histoire />} />*/}
              <Route path="/histoire" element={<Historique />} />
              <Route path="/services" element={<Sav />} />
              <Route path="/team" element={<Team />} />
              <Route path="/training" element={<Training />} />
              <Route path="/filtreactive" element={<Filtreactive />} />
              <Route path="/produit" element={<Produits />} />
              <Route
                path="Coffret_Protection_Transformateur"
                element={<Coffret />}
              />
              <Route path="/tableauais" element={<Tableauais />} />
              <Route
                path="/transformateur_dist_24"
                element={<Transformateur_DIST_24 />}
              />
              <Route
                path="/transformateur_dist_36"
                element={<Transformateur_DIST_36 />}
              />
              <Route
                path="/transformateur_mono"
                element={<Transformateur_Mono />}
              />
              <Route
                path="/transformateur_pui_triph"
                element={<Transformateur_pui_TRIPH />}
              />
              <Route
                path="/transformateur_special"
                element={<Transformateur_Special />}
              />
              <Route
                path="/batterie_condencateur"
                element={<Batterie_Condencateur />}
              />
              <Route path="/transformateur" element={<Transformateur />} />
              <Route path="/faqs" element={<Faq />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/nouscontacter" element={<Nouscontacter />} />
              <Route path="/commerciaux" element={<Commerciaux />} />
              <Route path="/form" element={<Form />} />
              <Route path="/certificats" element={<Certificats />} />
              <Route path="/carriers" element={<Rhcarriers />} />
              <Route path="/eems" element={<Eems />} />
              <Route path="/actualites" element={<Actualites />} />
              <Route path="/tableauMT-BT" element={<Power />} />
            </Routes>
          </BrowserRouter>
        </div>

        <div id="back-to-top">
          <a className="top arrow" href="#top">
            <i className="fa fa-angle-up" /> <span>TOP</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
