import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Loader from "./Loader";

const Transformateur_Special = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        <section
          className="page-title bg-overlay-black-60 parallax"
          style={{
            backgroundImage:
              "url(/assets/images/transformateur/Triphase/slider-triphase-36-24.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>SACEM INDUSTRIES</h1>
                  <p>innovative Technologies to Bring Electricity</p>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="../index.html">
                      <i className="fa fa-home" /> Accueil
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>Transformateur Spécials</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
 feature */}
        <section className="service white-bg page-section-ptb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect">
                    Nos Gammes de Transformateurs Spéciaux
                  </h2>
                </div>
              </div>
            </div>
            {/* ============================================ */}
            <div className="service-3">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  {/** */}
                  <div
                    className="section-title slideInLeft wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInLeft",
                    }}
                  >
                    <div className="service-blog text-left">
                      {/** <h3 className="theme-color">BPN</h3>*/}

                      <h3 className="theme-color">
                        Transformateurs de mise à la terre
                      </h3>
                      <br />

                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderRight: "0px solid white" }}
                      >
                        <li>
                          Les transformateurs de mise à la terre sont utilisés
                          pour fournir une connexion neutre pour la mise à la
                          terre d’un réseau triphasé. Ce type de transformateur
                          peut être également équipé d’un enroulement secondaire
                          destiné à être couplé à une charge auxiliaire.
                        </li>
                      </ul>
                    </div>
                    <div
                      style={{
                        paddingBottom: "1.25rem",
                        paddingLeft: "1.25rem",
                      }}
                    >
                      <a className="button button-border" href="/contact">
                        Nous Contacter
                      </a>
                    </div>

                    <br />
                    <br />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div className="col-lg-12 col-md-12">
                      <h3 className="mb-20" />
                      <div
                        className="owl-carousel bottom-center-dots"
                        data-nav-dots="ture"
                        data-items={1}
                        data-md-items={1}
                        data-sm-items={1}
                        data-xs-items={1}
                        data-xx-items={1}
                      >
                        <div>
                          <img
                            className="img-fluid"
                            src="/assets/images/transformateur/Specials/BPN.png"
                            alt
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ============================================ */}
              <div className="row">
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <h3 className="mb-20" />
                    <div
                      className="owl-carousel bottom-center-dots"
                      data-nav-dots="ture"
                      data-items={1}
                      data-md-items={1}
                      data-sm-items={1}
                      data-xs-items={1}
                      data-xx-items={1}
                    >
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/images/about/02.jpg"
                          alt
                        />
                      </div>
                      <div>
                        <img
                          className="img-fluid"
                          src="/assets/images/about/02.jpg"
                          alt
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  {/** */}
                  <div
                    className="slideInRight wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInRight",
                    }}
                  >
                    <div className="service-blog left text-left">
                      <h3 className="theme-color">
                        Transformateur d'isolement
                      </h3>
                      <br />

                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderLeft: "0px solid white" }}
                      >
                        <li>
                          Un transformateur d'isolement est conçu pour isoler
                          électriquement un circuit électrique d'un autre, tout
                          en permettant le transfert de puissance. Il est
                          couramment utilisé pour sécuriser des équipements
                          sensibles. (Équipements médicaux, Équipements
                          informatiques…)
                        </li>
                      </ul>
                    </div>
                    <div
                      style={{
                        paddingBottom: "1.25rem",
                        paddingLeft: "1.25rem",
                      }}
                    >
                      <a className="button button-border" href="/contact">
                        Nous Contacter
                      </a>
                    </div>
                  </div>
                  {/** */}
                </div>
              </div>
              {/* ============================================ */}
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  {/** */}
                  <div
                    className="section-title slideInLeft wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInLeft",
                    }}
                  >
                    <div className="service-blog text-left">
                      <h3 className="theme-color">Autotransformateur</h3>
                      <br />

                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderRight: "0px solid white" }}
                      >
                        <li>
                          Contrairement aux transformateurs classiques où les
                          enroulements primaire et secondaire sont complètement
                          séparés, l'autotransformateur utilise un seul
                          enroulement, avec une partie de celui-ci en commun. Il
                          est couramment utilisé pour les applications de
                          démarrage des moteurs triphasés aussi que pour ajuster
                          la tension selon les besoins. (dans les stabilisateurs
                          de tension…)
                        </li>
                      </ul>
                    </div>
                    <div
                      style={{
                        paddingBottom: "1.25rem",
                        paddingLeft: "1.25rem",
                      }}
                    >
                      <a className="button button-border" href="/contact">
                        Nous Contacter
                      </a>
                    </div>
                    <br />
                    <br />
                  </div>
                  {/** */}
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row mt-70">
                    <div className="col-lg-12 col-md-12">
                      <h3 className="mb-20" />
                      <div
                        className="owl-carousel bottom-center-dots"
                        data-nav-dots="ture"
                        data-items={1}
                        data-md-items={1}
                        data-sm-items={1}
                        data-xs-items={1}
                        data-xx-items={1}
                      >
                        <div>
                          <img
                            className="img-fluid"
                            src="/assets/images/about/02.jpg"
                            alt
                          />
                        </div>
                        <div>
                          <img
                            className="img-fluid"
                            src="/assets/images/about/02.jpg"
                            alt
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ============================================ */}
            </div>
          </div>
        </section>
        {/*=====================================*/}
        {/*=================================
 feature */}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Transformateur_Special;
