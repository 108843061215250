import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Loader from "./Loader";

const Historique = () => {
  const Textstyle = {
    fontSize: "22px",
    lineHeight: "28px",
    fontWeight: "500",
    color: "#222c5a",
  };
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  const DateStyle = {
    top: 0,
    left: 0,
    position: "absolute",
    right: 0,
    textAlign: "center",
    width: "100%",
  };

  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
  page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          data-jarallax='{"speed": 0.6}'
          /*style={{ backgroundImage: "url(/assets/images/bg/02.jpg)" }}*/
          style={{
            backgroundImage: "url(/assets/images/Bannier/histoire.png)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>Histoire</h1>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home" /> Accueil
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>Histoire</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================page-title */}
        {/*=================================blog timeline*/}
        <div className="container">
          <section className="white-bg blog timeline-sidebar page-section-ptb">
            <div className="container">
              <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-lg-9">
                  <ul className="timeline">
                    <li className="entry-date">
                      {" "}
                      <span>
                        <strong style={Textstyle}>2024</strong>{" "}
                      </span>
                    </li>
                    {/* =================2022===================== */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          2022 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                Conception et fabrication du transformateur de
                                puissance 26 MVA
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* ====================2021======================= */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          2021 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                Conception et fabrication du transformateur de
                                puissance 20 MVA
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>

                    {/* =======================2020==================== */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          2020 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                Développement de la solution gestion de
                                l'énergie
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>

                    {/* ==================2019========================= */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          2019 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                Homologation des cellules HTA
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* =======================2017==================== */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          2017 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                Creation de SACEM POWER SYSTEMS
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* =========================================== */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          2016 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                Developement du smart transformer
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* ==================2007========================= */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          2007 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>Privatisation de SACEM</p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* ==================2005========================= */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          2005 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                Conception transformateur 15 MVA
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* ==================1987========================= */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          1987 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                Station d'essaie transformateur 5 MVA
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* ===============1986============================ */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          1986 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                Certification Internationales
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* ================1975=========================== */}
                    <li className="timeline-inverted">
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          1975 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                Les premières opérations export
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>

                    {/* ================1966=========================== */}
                    <li
                      className="timeline-inverted"
                      style={{ marginBottom: "30px" }}
                    >
                      <div
                        className="timeline-badge primary"
                        style={{
                          display: "flex",
                          width: "75px",
                          height: "75px",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href="#"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          1966 <span></span>
                        </a>
                      </div>
                      <div className="timeline-panel">
                        <div className="blog-entry">
                          {/** <div className="entry-image clearfix">
                        <img className="img-fluid" src="" alt="img1" />
                      </div>*/}

                          <div className="blog-detail">
                            <div className="entry-title mb-10">
                              <p style={Textstyle}>
                                1966, Creation de SACEM, filiale de la STEG
                              </p>
                            </div>
                            {/**  <div className="entry-content">
                          <p></p>
                        </div>
                        <div className="entry-share clearfix">
                          <div className="social list-style-none float-right">
                            <strong> </strong>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </li>

                    {/* =========================================== */}

                    <li
                      className="entry-date-bottom"
                      style={{ marginTop: "15px", display: "flex" }}
                    >
                      {" "}
                      <a href="">
                        <strong style={Textstyle}>Creation de SACEM </strong>{" "}
                      </a>
                    </li>
                    {/* =========================================== */}
                    <li
                      className="clearfix timeline-inverted"
                      style={{ float: "none" }}
                    />
                  </ul>
                </div>
                {/* ================================= */}
              </div>
            </div>
          </section>
        </div>
        {/*=================================blog timeline*/}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Historique;
