import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Loader from "./Loader";
const Filtreactive = () => {
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          data-jarallax='{"speed": 0.6}'
          style={{ backgroundImage: "url(/assets/images/slidebat.png)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>Filtre active anti-harmoniques</h1>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home" /> Accueil
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>Filtre Active</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
 Premium Features */}
        <section className="service white-bg page-section-ptb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect">
                    Filtre active anti-harmoniques
                  </h2>
                </div>
              </div>
            </div>
            {/* ============================================ */}
            <div className="service-3">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="row slideInLeft wow">
                    <div className="service-blog text-Left">
                      <h3 className="theme-color">Application</h3>
                      <p>
                        Élimination/compensation des harmoniques pour améliorer
                        la qualité et l'efficacité de l'installation électrique
                        :
                      </p>
                      {/*<b>01</b>*/}
                      <ul className="list list-unstyled">
                        <li>
                          Harmoniques générés par des machines industrielles
                          (soudage, extrudeuses, machines à injection, etc.){" "}
                        </li>
                        <li>
                          Élimination de la 3ème harmonique générée par des
                          charges monophasées
                        </li>
                        <li>Centres de données</li>
                        <li>Systèmes UPS</li>
                        <li>
                          Production d'énergie (photovoltaïque et éolienne)
                        </li>
                        <li>Centres commerciaux</li>
                        <li>Bâtiments de bureaux</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <img
                    className="img-fluid full-width"
                    src="/assets/images/filtre-active-pqsine.jpg"
                    alt="filtre-active-pqsine"
                  />
                  <div style={{ paddingTop: "80px" }}>
                    <a
                      href="/contact"
                      className="button button-border"
                      style={{
                        fontSize: 10,
                        width: "30%",
                        padding: 10,
                        display: "inline",
                      }}
                    >
                      Nous Contacter
                    </a>
                  </div>
                </div>
              </div>
              {/* ============================================ */}
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <img
                    className="img-fluid full-width"
                    src="/assets/images/Filtre-active02.JPG"
                    alt="Filtre-active02"
                  />
                  <div style={{ paddingTop: "80px" }}>
                    <a
                      href="/contact"
                      className="button button-border"
                      style={{
                        fontSize: 10,
                        width: "30%",
                        padding: 10,
                        display: "inline",
                      }}
                    >
                      Nous Contacter
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div className="row slideInRight wow">
                    <div className="service-blog left text-left">
                      <h3 className="theme-color">Caractéristiques</h3>
                      <p>
                        Compensation des harmoniques jusqu'à la 50ème harmonique
                        (sélection individuelle de l'harmonique à compenser)
                      </p>
                      {/*<b>02</b>*/}
                      <ul className="list list-unstyled">
                        <li>
                          Compensateur de "flickers" (distorsion d'amplitude de
                          la tension){" "}
                        </li>
                        <li>Compensation réactive "ultra-rapide"</li>
                        <li>Design compact</li>
                        <li>
                          Détection des effets de résonance dans l'installation
                        </li>
                        <li>Haute performance et fiabilité</li>
                        <li>Menu de navigation facile à utiliser</li>
                        <li>Installation rapide et simple</li>
                        <li>Fixes ou modulaires</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* ============================================ */}
              {/* ============================================ */}
            </div>
          </div>
        </section>
        {/*=================================
 Premium Features */}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Filtreactive;
