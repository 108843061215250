import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "./Footer";
import Header from "./Header";
import Loader from "./Loader";

const Training = () => {
  const StyledHeading = styled.h6`
    position: relative;
    font-size: 100px;
    color: #008bcd;

    &::before {
      content: "—";
      font-size: inherit;
      color: #008bcd;
      display: block;
    }
  `;
  // State to control visibility
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Delay the visibility of the component
    const timer = setTimeout(() => {
      setIsVisible(true); // Set to visible after the delay
    }, 200);

    return () => clearTimeout(timer); // Clean up on unmount
  }, []);
  return (
    <div>
      <div>
        <Header />
      </div>
      <Loader isVisible={isVisible} />
      <div>
        {/*=================================
page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          data-jarallax='{"speed": 0.6}'
          style={{ backgroundImage: "url(/assets/images//Train-01.JPG)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>SACEM TRAINING</h1>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home" /> Accueil
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>

                  <li>
                    <span>SACEM TRAINING</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
 Premium Features */}
        <section className="service white-bg page-section-ptb">
          <div className="container">
            {/**  <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-title text-center">
                  <h2 className="title-effect">SACEM TRAINING</h2>
                </div>
              </div>
            </div> */}

            {/* ============================================ */}
            <div className="service-3">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div
                    className="section-title slideInLeft wow"
                    style={{
                      visibility: "visible",
                      animationName: "slideInLeft",
                    }}
                  >
                    <div className="service-blog text-left">
                      <div className="section-title">
                        <h2 className="title-effect">
                          A propos de SACEM TRAINING
                        </h2>
                        <StyledHeading className="h6-line"></StyledHeading>
                      </div>
                      <p>
                        SACEM TRAINING est le premier centre de son genre dans
                        la région de MENA avec sa spécialisation dans le domaine
                        de l'électricité et de l'énergie. Nos sessions de
                        formation sont proposées comme suit
                      </p>
                      <ul
                        className="list list-unstyled list-hand"
                        style={{ borderRight: "0px solid white" }}
                      >
                        <li>
                          <h4
                            style={{
                              fontWeight: "bold",
                              fontSize: "18px",
                            }}
                          >
                            INTER ENTREPRISES
                          </h4>
                        </li>

                        <p style={{ paddingLeft: "25px" }}>
                          Plusieurs entreprises participent à une même session
                          de formation sur un thème prédéfini. SACEM TRAINING
                          accueille les participants dans ses salles de
                          formation.
                        </p>
                        <li>
                          <h4
                            style={{
                              fontWeight: "bold",
                              fontSize: "18px",
                            }}
                          >
                            INTRA ENTREPRISES
                          </h4>
                        </li>
                        <p style={{ paddingLeft: "25px" }}>
                          Les participants à ces sessions appartiennent à une
                          même entreprise, d'où l'avantage de personnaliser les
                          cours de la session. La formation se déroule selon le
                          choix du client, soit dans ses propres locaux soit
                          dans les locaux de SACEM TRAINING.
                        </p>

                        <li>
                          <h4
                            style={{
                              fontWeight: "bold",
                              fontSize: "18px",
                            }}
                          >
                            ASSISTANCE SUR MESURE
                          </h4>
                        </li>
                        <p style={{ paddingLeft: "25px" }}>
                          SACEM TRAINING, vous accompagne avec son expertise
                          pour déterminer vos besoins en formation à travers un
                          audit, et vous propose la solution sur mesure
                          appropriée.
                        </p>
                        <p style={{ paddingLeft: "25px" }}>
                          Production d'énergie (photovoltaïque et éolienne)
                        </p>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 xs-mt-30 xs-mb-30">
                  <div style={{ paddingTop: "3rem" }}>
                    <img
                      className="img-fluid full-width"
                      src="/assets/images//Train-01.JPG"
                      alt="filtre-active-pqsine"
                    />
                  </div>
                  <div
                    style={{
                      paddingBottom: "1.25rem",
                      paddingTop: "1.5rem",
                    }}
                  >
                    <a href="/Contact" className="button button-border">
                      Nous Contacter
                    </a>
                  </div>
                </div>
              </div>
              {/* ============================================ */}
              <div>
                <div className="col-md-12 text-center">
                  <h3>NOS DOMAINES DE FORMATION</h3>
                </div>
                {/**section 1 */}
                <section style={{ paddingTop: "30px" }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12"></div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "350px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">
                                  Secourisme et lutte contre l'incendie
                                </h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>Travail en hauteur</li>
                                  <li>Audit incendie</li>
                                  <li>Caces</li>
                                  <li>Risque Chimique</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              href="/Coffret_Protection_Transformateur"
                              className="button button-border"
                            >
                              Détail
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "350px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">COMMUNICATION</h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>Gestion du temps</li>
                                  <li>Gestion du stress</li>
                                  <li>Travail en équipe</li>
                                  <li>LEADERSHIP</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              href="/Coffret_Protection_Transformateur"
                              className="button button-border"
                            >
                              Détail
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "350px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">LEAN MANAGEMENT </h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>AMDEC</li>
                                  <li>KAIZEN</li>
                                  <li>5S</li>
                                  <li>LEADERSHIP</li>
                                  <li>
                                    Methode de resolution des problemes en
                                    groupe (MRPG)
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              href="/Coffret_Protection_Transformateur"
                              className="button button-border"
                            >
                              Détail
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/**section 2 */}

                <section style={{ paddingTop: "30px" }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12"></div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "380px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">ENERGIE</h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>
                                    Audit énergétique industriel pour les cadres
                                    d'entreprises : s'approprier des outils
                                    pratiques
                                  </li>
                                  <li>Bilan carbone</li>
                                  <li>Auditeurs internes ISO 50001</li>
                                  <li>Veille réglementaire en Énergie</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              href="/Coffret_Protection_Transformateur"
                              className="button button-border"
                            >
                              Détail
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "380px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">
                                  RISQUES INDUSTRIELS
                                </h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>
                                    Elaboration d'une Etude de dangers et d'un
                                    P.O.I
                                  </li>
                                  <li>Prévention des risques chimiques</li>
                                  <li>
                                    Gestion de stockage et transport des
                                    produits chimiques
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              href="/Coffret_Protection_Transformateur"
                              className="button button-border"
                            >
                              Détail
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "380px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">
                                  Qualité Sécurité environement
                                </h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>
                                    ISO 9001 Version 2015 : Exigences et
                                    techniques de mise en place
                                  </li>
                                  <li>ISO 14001 Version 2015</li>
                                  <li>Management des risques ISO 31000</li>
                                  <li>Audit interne QSE</li>
                                  <li>
                                    Exigences d'un système de management intégré
                                    QSE
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              href="/Coffret_Protection_Transformateur"
                              className="button button-border"
                            >
                              Détail
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {/**section 3 */}
                <section style={{ paddingTop: "30px" }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12"></div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "480px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">
                                  GESTION DES RESSOURCES HUMAINES
                                </h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>
                                    Identification des besoins en formation
                                  </li>
                                  <li>Tableau de bord GRH</li>
                                  <li>Gestion des cadres et des carrières</li>
                                  <li>
                                    Les techniques d'évaluation du personnel
                                  </li>
                                  <li>La gestion des dossiers des ATMP</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              href="/Coffret_Protection_Transformateur"
                              className="button button-border"
                            >
                              Détail
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "480px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">
                                  SANTÉ SÉCURITÉ AU TRAVAIL
                                </h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>
                                    La norme ISO 45001 version 2018 : exigences
                                    et techniques de mise en place
                                  </li>
                                  <li>
                                    Évaluation des risques professionnels et
                                    mesures de maîtrise
                                  </li>
                                  <li>
                                    NFC 18510 Préventions des risques
                                    électriques
                                  </li>
                                  <li>
                                    Enquête et analyse des accidents de travail
                                  </li>
                                  <li>
                                    Réglementation en Santé Sécurité au Travail
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              href="/Coffret_Protection_Transformateur"
                              className="button button-border"
                            >
                              Détail
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "480px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">
                                  GESTION DES PROJETS
                                </h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>Préparation à la certification PMP</li>
                                  <li>
                                    Les fondamentaux de management de projets
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              href="/Coffret_Protection_Transformateur"
                              className="button button-border"
                            >
                              Détail
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {/**section 4 */}
                <section style={{ paddingTop: "30px" }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12"></div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "300px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">
                                  Habilitation electrique
                                </h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>
                                    Maintenance des installations électriques
                                  </li>
                                  <li>
                                    Maintenance des lignes de transport et
                                    postes HT/MT/BT
                                  </li>
                                  <li>
                                    Exploitation, maintenance et réhabilitation
                                    des réseaux HTA/BT
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              href="/Coffret_Protection_Transformateur"
                              className="button button-border"
                            >
                              Détail
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "300px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">
                                  Maintenance des Transformateurs
                                </h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>Maintenance préventive</li>
                                  <li>
                                    Maintenance des lignes de transport et
                                    postes HT/MT/BT
                                  </li>
                                  <li>Maintenance corrective</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              href="/Coffret_Protection_Transformateur"
                              className="button button-border"
                            >
                              Détail
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 sm-mb-30">
                        <div className="card">
                          <div
                            className="card-body"
                            style={{ minHeight: "300px" }}
                          >
                            <div
                              className="feature-text "
                              style={{
                                paddingTop: "1.25rem",
                                textAlign: "start",
                              }}
                            >
                              <div className="feature-info">
                                <h5 className="text-back">Photovoltaïque</h5>
                                <br />
                                <ul
                                  className="list list-unstyled list-hand"
                                  style={{ borderRight: "0px solid white" }}
                                >
                                  <li>Systemes photovoltaïque</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              paddingBottom: "1.25rem",
                              paddingLeft: "1.25rem",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <a
                              href="/Coffret_Protection_Transformateur"
                              className="button button-border"
                            >
                              Détail
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              {/* ============================================ */}
              {/*Commercial Training*/}
              <section>
                <div className="row mt-100">
                  <div className="col-lg-12">
                    <h2 style={{ color: "#00356a" }}>
                      Commerciaux SACEM Training
                    </h2>
                    <StyledHeading className="h6-line"></StyledHeading>
                    <br />
                  </div>
                  <div className="col-lg-6 col-md-6 sm-mb-30">
                    <div className="team team-list">
                      <div className="team-photo">
                        <img
                          className="img-fluid mx-auto"
                          src="/assets/images/equipe_training/Battick.jpg"
                          alt
                        />
                      </div>
                      <div className="team-description">
                        <div className="team-info">
                          <h5>
                            <a href="team-single.html">Mohamed Aziz BATTIK</a>
                          </h5>
                          <span>Commercial Training</span>
                        </div>
                        <div className="team-contact">
                          <span className="call"> (+216) 29 922 611</span>
                          <span className="email">
                            {" "}
                            <i className="fa fa-envelope-o" />
                            MohamedAziz.BATTIKH@sacemgroup.com
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="team team-list">
                      <div className="team-photo">
                        <img
                          className="img-fluid mx-auto"
                          src="/assets/images/equipe_training/radhia.jpg"
                          alt
                        />
                      </div>
                      <div className="team-description">
                        <div className="team-info">
                          <h5>
                            <a href="team-single.html">Radhia MLIKI</a>
                          </h5>
                          <span>Commercial Training</span>
                        </div>
                        <div className="team-contact">
                          <span className="call"> (+216) 28 505 123</span>
                          <span className="email">
                            {" "}
                            <i className="fa fa-envelope-o" />
                            radia.mliki@sacemtraining.com
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* ============================================ */}
              {/* ============================================ */}
              {/*Partenaire*/}
              <section>
                <div className="row mt-100">
                  <div className="col-lg-12 col-md-12">
                    <h2 style={{ color: "#00356a" }}>Nos références clients</h2>
                    <StyledHeading className="h6-line"></StyledHeading>
                    <br />
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="clients-list">
                      <div
                        className="owl-carousel"
                        data-nav-dots="false"
                        data-items={5}
                        data-md-items={4}
                        data-sm-items={3}
                        data-xs-items={2}
                        data-xx-items={2}
                      >
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/Agil.png"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/client03.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/CMR.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/Fouledh.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/Hutch.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/KBE.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/steg.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/Warda.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/CMR.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/Hutch.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/fuba.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/g.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/eico.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/groupe mmi.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/vitalait.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/sicam.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/délice.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/misfat.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/eleonetech.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/yazaki.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/icar.JPG"
                            alt
                          />
                        </div>{" "}
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/safran.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/giz.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/kromberg.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/bpm.JPG"
                            alt
                          />
                        </div>{" "}
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/epi d'or.JPG"
                            alt
                          />
                        </div>{" "}
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/unimed.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/cpc.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/gruner.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/cofat.JPG"
                            alt
                          />
                        </div>{" "}
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/cnp.JPG"
                            alt
                          />
                        </div>{" "}
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/carthage cement.JPG"
                            alt
                          />
                        </div>{" "}
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/bcm.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/clinique elissa.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/omv.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/poulina.JPG"
                            alt
                          />
                        </div>{" "}
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/Amilcar Petroleum Operations.JPG"
                            alt
                          />
                        </div>
                        <div className="item">
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/images/Client_training/Landor.JPG"
                            alt
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* ============================================ */}
          </div>
        </section>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Training;
