import React from "react";

const Maps = () => {
  return (
    <div>
      <div>
        {/*=================================
page-title*/}
        <section
          className="page-title bg-overlay-black-60 parallax"
          data-jarallax='{"speed": 0.6}'
          style={{ backgroundImage: "url(/assets/images/bg/02.jpg)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title-name">
                  <h1>maps</h1>
                  <p>Visitez nos Locaux , vous êtes chez vous</p>
                </div>
                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">
                      <i className="fa fa-home" /> Accueil
                    </a>{" "}
                    <i className="fa fa-angle-double-right" />
                  </li>
                  <li>
                    <span>maps</span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
page-title */}
        {/*=================================
 Maps */}
        <section className="page-section-ptb">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 mb-40">
                <h4 className="mb-30">Sacem Siege Social</h4>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3193.086178847699!2d10.241555575550137!3d36.840412065432204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd3544308c6a69%3A0xc5d7ab77eb5d5190!2sSacem%20Industries!5e0!3m2!1sfr!2stn!4v1703515189759!5m2!1sfr!2stn"
                  width={500}
                  height={350}
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 mb-40">
                <h4 className="mb-30">Sacem Usine</h4>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3180.2509361242182!2d9.770430675563889!3d37.1467323480896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12e3247f3bebfd41%3A0x7f5da2fd84a32374!2sSACEM%20INDUSTRIES%20USINE!5e0!3m2!1sfr!2stn!4v1703517144700!5m2!1sfr!2stn"
                  width={500}
                  height={350}
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
        </section>
        {/*=================================
 Maps */}
      </div>
    </div>
  );
};

export default Maps;
